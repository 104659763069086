@import 'src/scss/variables.scss';

.footer-container {
    font-family: 'Hubot Sans' !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $size-footer-height;
    // font-size: $font-size-s1;
    color: $color-offwhite;
    background-color: $color-secondary;
    .container {
        .row {
            .logo {
                width: 100%;
                max-width: 150px;
                cursor: pointer;
                img {
                    height: auto;
                    width: 100%;
                }
            }
            .all-rights-reserved {
                margin-top: 5px;
            }
            .links {
                @media (min-width: 768px) {
                    margin-top: 10px;
                }
                .primary-link {
                    cursor: pointer;
                    &:hover {
                        color: white;
                        text-decoration: underline;
                    }
                }
                .dropdown-wrapper {
                    padding-left: 10px;
                    width: auto;
                    .dropdown-link {
                        font-size: $font-size-s2;
                        margin-bottom: 2.5px;
                        width: auto;
                        cursor: pointer;
                        &:hover {
                            color: white;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .address {
                @media (min-width: 768px) {
                    margin-top: 10px;
                }
            }
            .social-media {
                display: flex;
                gap: 15px;
                a {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: white;
                    color: $color-secondary;
                    &:hover {
                        background-color: $color-offwhite;
                    }
                }
            }
        }
    }
}
