@import 'src/scss/variables.scss';
@import '../Product/Product.scss';

.testimonial {
    &.card-container {
        .data-card-wrapper {
            background-color: white;
            box-shadow: 0px 10px 10px $color-box-shadow;
            border: 1px solid $color-tertiary-2;
            border-radius: 5px;
            .content-header {
                color: $color-tertiary-1;
                font-size: 2em;
                line-height: 1;
            }
            .content-data {
                font-style: italic;
                margin-bottom: calc(15px + 40px + 35px);
            }
            .link-btn {
                .separator {
                    width: 40px;
                    height: 2px;
                    background-color: $color-secondary;
                    margin: 20px auto;
                }
                button {
                    width: 100%;
                }
            }
        }
    }
}
