@import "src/scss/variables.scss";

.header-data-container {
    $padding-gap: 5px;
    font-family: "Hubot Sans" !important;
    background-color: $color-secondary;
    height: $size-header-height;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    .header-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .logo {
            width: 150px;
            cursor: pointer;
            img {
                height: auto;
                width: 100%;
            }
        }
        .partner-logo {
            cursor: pointer;
            height: 100%;
            min-width: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0px;
            margin-left: 20px;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
        .links {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            margin-top: 14px;
            margin-left: 40px;
            @media (min-width: 768px) and (max-width: 991px) {
                gap: 10px;
            }
            .link-wrapper {
                position: relative;
                .primary-link {
                    color: white;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .menu-wrapper {
            color: white;
            margin-left: auto;
            .menu-option {
                font-size: 2em;
                margin-bottom: 6px;
                cursor: pointer;
                @media (max-width: 575px) {
                    margin-bottom: 0px;
                }
                &:hover {
                    color: $color-offwhite;
                }
            }
            .small-screen-links {
                position: absolute;
                top: $size-header-height;
                right: 15px;
                background-color: $color-secondary;
                padding: 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                min-width: 200px;
                .link-wrapper {
                    padding: $padding-gap 0px;
                    .primary-link {
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .dropdown-wrapper {
                        padding-left: 10px;
                        .dropdown-link {
                            padding: $padding-gap 0px;
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .bootstrap-dropdown-link-wrapper {
            .dropdown {
                button {
                    font-size: 1em;
                    color: white;
                    cursor: pointer;
                    border: none;
                    padding: 0px;
                    background-color: transparent;
                    &:hover {
                        text-decoration: underline;
                    }
                    &::after {
                        display: none;
                    }
                    @media (max-width: 767px) {
                        padding: $padding-gap 0px;
                    }
                }
                .dropdown-menu {
                    background-color: $color-secondary;
                    border: 1px solid $color-primary;
                    border-top-width: 5px;
                    border-radius: 5px;
                    padding: 0px;
                    &:hover {
                        border-color: white;
                        .dropdown-link {
                            border-color: white;
                        }
                    }
                    a.dropdown-item {
                        font-size: $font-size-s2;
                        color: white;
                        padding: 5px 10px;
                        border-bottom: 1px solid $color-primary;
                        cursor: pointer;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover {
                            // color: $color-font;
                            background-color: $color-primary;
                            text-decoration: underline;
                        }
                    }
                }
            }
            &.last-link {
                @media (min-width: 768px) {
                    margin-left: auto;
                    .dropdown {
                        button {
                            border: 1px solid $color-primary;
                            border-radius: 5px;
                            padding: 5px 30px;
                            text-align: center;
                            @media (min-width: 768px) and (max-width: 991px) {
                                padding: 5px 15px;
                            }
                            &:hover {
                                text-decoration: none;
                                border: 1px solid white;
                                background-color: $color-primary;
                            }
                        }
                        .dropdown-menu {
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
