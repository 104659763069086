@import 'src/scss/variables.scss';

.industry-container {
    .bordered-img-wrapper {
        img {
            cursor: pointer;
            &:hover,
            &:focus {
                padding: 2.5px;
                background-color: $color-secondary;
            }
        }
    }
    .industry-feature-card {
        .industry-card-wrapper {
            cursor: pointer;
            .logo-wrapper {
                margin-bottom: 15px;
                img {
                    background-color: $color-primary;
                    width: 50px;
                    height: 50px;
                    margin: 0px auto;
                    padding: 5px;
                    border-radius: 50%;
                    box-shadow: 0px 0px 5px $color-box-shadow;
                    &:hover,
                    &:focus {
                        padding: 7.5px;
                    }
                }
            }
            .content-header {
                font-weight: bold;
                margin-bottom: 10px;
            }
        }
    }
}
