@import 'src/scss/variables.scss';

.flight-checker-wrapper {
    .content-wrapper {
        .logo {
            margin-bottom: 30px;
            img {
                height: auto;
                width: 100%;
            }
        }
        .text-content {
            max-width: 80%;
        }
    }
    .separator-wrapper {
        padding: 40px 20px;
        height: 100%;
        .separator {
            $color-separator: $color-secondary;
            $color-transparent: #00000000;
            height: 100%;
            background: linear-gradient(
                to top left,
                $color-transparent 0%,
                $color-transparent calc(50% - 1.5px),
                $color-separator 50%,
                $color-transparent calc(50% + 1.5px),
                $color-transparent 100%
            );
        }
        @media (max-width: 767px) {
            padding: 10px 40px;
            height: 80px;
        }
        @media (max-width: 575px) {
            padding: 10px 10px;
        }
    }
}
.flight-checker-form {
    $size-margin: 15px;
    color: white;
    margin-top: 20px;
    .re-captcha-info {
        font-size: $font-size-s1;
        margin: 20px 0px $size-margin;
        .link {
            text-decoration: underline;
            color: white;
        }
    }
    .cust-form-group {
        margin-bottom: $size-margin;
        label {
            display: block;
            margin-bottom: 2px;
        }
        input,
        textarea {
            line-height: 1.5;
            font-size: $font-size-normal;
            display: block;
            padding: 5px 15px;
            width: 100%;
            color: white;
            background: $color-secondary;
            outline: none;
            box-shadow: none;
            border: none;
            border-radius: 5px;
            &:hover,
            &:focus {
                outline: 1px solid white;
            }
        }
        &.button-wrapper {
            text-align: right;
            margin-bottom: 0px;
            button {
                border-radius: 5px;
                border: none;
                outline: none;
                padding: 10px 15px;
                color: white;
                background-color: $color-secondary;
                border: 1px solid $color-primary;
                &:hover {
                    background-color: $color-primary;
                    border-color: white;
                }
            }
        }
        .rbt .rbt-menu.dropdown-menu {
            background-color: $color-secondary;
            padding: 0px;
            border: 1px solid white;
            border-top-width: 5px;
            border-radius: 5px;
            // &:hover {
            //     border-color: white;
            //     .dropdown-item {
            //         border-color: white;
            //     }
            // }
            .dropdown-item {
                color: white;
                padding: 5px 10px;
                border-bottom: 1px solid white;
                font-size: $font-size-normal;
                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: $font-size-s2 !important;
                }
                @media (max-width: 575px) {
                    font-size: $font-size-s2 !important;
                }
                &:last-child {
                    border-bottom: none;
                }
                &:hover {
                    background-color: $color-primary;
                }
            }
        }
    }
}
