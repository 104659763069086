@import 'src/scss/variables.scss';

$size-contact-width: 400px;
$size-toggle-contact-height: 33px;
$size-toggle-contact-width: 150px;
.contact-container {
    z-index: 100;
    position: fixed;
    top: 50vh;
    left: calc(-1 * #{$size-contact-width});
    transform: translateY(-50%);
    width: $size-contact-width;
    background: $color-primary;
    padding: 20px 10px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid $color-secondary;
    border-left: none;
    box-shadow: 0px 5px 15px $color-box-shadow;
    transition: 0.5s linear all;
    button#toggle-contact {
        z-index: 10;
        position: absolute;
        top: calc(50% - #{$size-toggle-contact-height} / 2);
        right: calc(-1 * (#{$size-toggle-contact-width} / 2 + #{$size-toggle-contact-height} / 2 - 0.5px));
        transform: rotate(90deg);
        height: $size-toggle-contact-height;
        width: $size-toggle-contact-width;
        line-height: $size-toggle-contact-height;
        padding: 0px;
        color: white;
        background-color: $color-secondary;
        border: 1px solid $color-primary;
        border-bottom: none;
        border-radius: 5px 5px 0px 0px;
        outline: none;
        box-shadow: 0px -2.5px 5px $color-box-shadow;
        &:hover {
            box-shadow: none;
            background-color: $color-primary;
            border-color: white;
        }
    }
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: white;
        background-color: $color-secondary;
        border: 1px solid $color-primary;
        &:hover {
            background-color: $color-primary;
            border-color: white;
        }
    }
}
