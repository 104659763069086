$color-primary: #e7c418;
$color-secondary: #161615;
$color-tertiary: white;
$color-tertiary-1: #6c85b1;
$color-tertiary-2: #f5f4f4;
$color-tertiary-3: #4d4d4d;
$color-font: #212529;
$color-offwhite: #dddddd;
$color-border: #c0c0c0;
$color-link: #227afd;
$color-background-brightness: #00000088;
$color-box-shadow: #00000088;

$size-container-large-width: 1800px;
$size-container-small-width: 800px;
$size-header-height: 80px;
$size-footer-height: 350px;
$size-page-min-height: calc(100vh - #{$size-header-height} - #{$size-footer-height});

$font-size-normal: 1em;
$font-size-s1: 0.9em;
$font-size-s2: 0.8em;
$font-size-l1: 1.1em;
$font-size-l2: 1.2em;

$font-weight-med: 600;
$size-form-border-radius: 5px;
