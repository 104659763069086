@import 'src/scss/variables.scss';

$size-padding-side: 20px;
$size-padding-bottom: 30px;
.product {
    &.card-container {
        color: white;;
        .data-card-wrapper {
            background-color: $color-tertiary-1;
            .logo-wrapper {
                height: 60px;
                img {
                    max-width: 70px;
                }
            }
            .link-btn {
                button {
                    min-width: 70%;
                }
            }
        }
    }
}
.data-card-wrapper {
    height: 100%;
    border-radius: 10px;
    position: relative;
    padding: 20px $size-padding-side $size-padding-bottom;
    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        height: 40px;
        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .content-header {
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content-data {
        text-align: center;
        margin-bottom: calc(15px + 35px);
    }
    .link-btn {
        position: absolute;
        bottom: $size-padding-bottom;
        left: $size-padding-side;
        right: $size-padding-side;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
