@import 'src/scss/variables.scss';

.home-data-container {
    .mission-container {
        background-color: $color-primary;
        text-align: center;
    }
    .travel-disruption-container {
        $size-media-height: 350px;
        color: $color-primary;
        background-color: $color-tertiary-3;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
            line-height: 1.2;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;
        }
        .media-wrapper {
            max-width: 500px;
            margin: 0px auto;
            .media-content {
                position: relative;
                width: 100%;
                overflow: hidden;
                padding-top: calc(100% * 9 / 16);
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }
    // .product-container {
    // }
    .flight-checker-container {
        background-color: $color-primary;
    }
    // .industries-container-container {
    // }
    .testimonial-container {
        background-color: $color-tertiary-2;
    }
}
