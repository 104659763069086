@import "src/scss/variables.scss";

.page-404-data-container {
    .header-container {
        text-align: center;
        h1 {
            font-size: 6em;
        }
        .not-found-text {
            margin: 20px 0px 40px;
        }
    }
    .flight-checker-container {
        background-color: $color-primary;
    }
}
