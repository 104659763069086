@import "src/scss/variables.scss";

@font-face {
  font-family: "Mona Sans";
  src:
    url("../public/fonts/Mona-Sans.woff2") format("woff2 supports variations"),
    url("../public/fonts/Mona-Sans.woff2") format("woff2-variations");
  font-weight: 400 600 700;
  font-stretch: 125%;
}
@font-face {
  font-family: "Hubot Sans";
  src:
    url("../public/fonts/Hubot-Sans.woff2") format("woff2 supports variations"),
    url("../public/fonts/Hubot-Sans.woff2") format("woff2-variations");
  font-weight: 400 600 700;
  font-stretch: 100%;
}

body {
  margin: 0;
  font-family: "Mona Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-font;
  font-size: $font-size-normal;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: $font-size-s2 !important;
  }
  @media (max-width: 575px) {
    font-size: $font-size-s2 !important;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.min-full-screen-height {
  min-height: 100vh !important;
}
.full-screen-width {
  width: 100vw !important;
}
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100% !important;
}
.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}
.page-min-height {
  min-height: $size-page-min-height !important;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.container-side-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.container-content-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.container-small-width {
  max-width: $size-container-small-width;
  width: 100%;
  margin: 0px auto;
}
.container-large-width {
  max-width: $size-container-large-width;
  width: 100%;
  margin: 0px auto;
}

.text-med {
  font-weight: $font-weight-med;
}
.text-error {
  color: red;
  font-size: $font-size-s1;
}
.text-l2 {
  font-size: $font-size-l2 !important;
}
.text-justify {
  text-align: justify;
}
.text-link {
  color: $color-link;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.header-container {
  color: white;
  background-color: $color-tertiary-3;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    line-height: 1.2;
    font-weight: bold;
  }
  .logo-wrapper {
    max-width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      margin: 0px auto;
    }
    img {
      width: 100%;
    }
  }
  p:last-child {
    @media (min-width: 768px) {
      margin: 0px;
    }
  }
}

button.btn-primary {
  color: white;
  background-color: $color-secondary;
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 5px 30px;
  &:hover {
    background-color: $color-primary;
    border-color: white;
  }
}

.cust-loader {
  $size-loader: 80px;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #ffffffaa;
  z-index: 999999;
  img {
    position: fixed;
    height: $size-loader;
    width: $size-loader;
    top: calc(50% - #{$size-loader} / 2);
    left: calc(50% - #{$size-loader} / 2);
    z-index: 999998;
  }
}

.bordered-img-wrapper {
  display: flex;
  justify-content: center;
  img {
    border-radius: 20px;
    border: 5px solid $color-secondary;
    width: 100%;
    max-width: 450px;
    height: auto;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.container,
.container-fluid {
  .row {
    margin: 0px -10px;
    @media (max-width: 767px) {
      margin: 0px 0px;
    }
    [class^="col-"] {
      padding: 0px 10px;
      @media (max-width: 767px) {
        padding: 0px;
      }
    }
    .small-screen-padding-top {
      @media (max-width: 767px) {
        padding-top: 20px;
      }
    }
    .large-screen-padding-top {
      @media (min-width: 768px) {
        padding-top: 30px;
      }
    }
  }
}

// hide arrows from input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// copied from airlegit-ui-new
.cust-form-group {
  input,
  textarea,
  select {
    width: 100%;
    margin-bottom: 10px;
    // line-height: 1.5;
    font-size: $font-size-normal;
    padding: 5px 15px;
    outline: none;
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: 5px;
    &:hover,
    &:focus {
      border-color: $color-primary;
    }
  }
  input,
  select {
    height: 36px;
  }
  .text-error {
    margin-top: -5px;
    margin-bottom: 5px;
  }
  &.button-wrapper {
    margin-top: 15px;
    text-align: right;
  }
}

.react-tel-input {
  input {
    height: 36px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-color: $color-border !important;
    border-radius: 5px !important;
    &:hover,
    &:focus {
      border-color: $color-primary !important;
    }
  }
}

